@tailwind base;
@tailwind components;
@tailwind utilities;

.ongoing {
  color: white;
  font-weight: bold;
  font-size: 0.65rem;
}

.glowing-button {
  display: inline-block;
  padding: 0.03rem 0.15rem;
  font-size: 0.65rem;
  font-weight: bold;
  color: white;
  border-radius: 0.15rem;
  background-color: #228B22;
  margin-left: 0.3rem;
  box-shadow: 0 0 6px #228B22;
}

.fancy {
  --offset: 3px;
  background: rgb(59, 130, 246);
  border-radius: 0.5rem;
  position: relative;
  height: auto;
  width: auto;
  max-width: 100%;
  overflow: hidden;
  padding: 0.5rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s;
  z-index: 1;
}

.fancy:hover {
  background: rgb(37, 99, 235);
}

.fancy::before { 
  content: '';
  background: conic-gradient(transparent 270deg, white, transparent);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  aspect-ratio: 1;
  width: 100%;
  animation: rotate 5s linear infinite;
  opacity: 0.2;
  z-index: -1;
}

.fancy::after {
  content: '';
  background: inherit;
  border-radius: inherit;
  position: absolute;
  inset: var(--offset);
  height: calc(100% - 2 * var(--offset));
  width: calc(100% - 2 * var(--offset));
  z-index: -1;
}

.blur-sm {
  filter: blur(5px);
}

.opacity-50 {
  opacity: 0.5;
}

.darken {
  filter: brightness(0.25);
}

.blur-darken {
  filter: blur(5px) brightness(0.25);
}

@keyframes rotate {
  from {
    transform: translate(-50%, -50%) scale(1.4) rotate(0turn);
  }
  to {
    transform: translate(-50%, -50%) scale(1.4) rotate(1turn);
  }
}

@keyframes textAnimation {
  0% { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
}

@keyframes subtlePulse {
  0%, 100% { opacity: 0.8; }
  50% { opacity: 0.6; }
}

.animate-subtle-pulse {
  animation: subtlePulse 2.5s ease-in-out infinite;
}

@keyframes fadeIn {
  from { opacity: 0; transform: scale(0.95); }
  to { opacity: 1; transform: scale(1); }
}

.animate-fade-in {
  animation: fadeIn 0.2s ease-out forwards;
}

@layer utilities {
  .brush-highlight {
    @apply relative inline-block;
  }
  .brush-highlight::after {
    content: "";
    @apply absolute inset-x-0 bottom-0 h-2 bg-indigo-500/40 -z-10;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.3s ease;
  }
  .brush-highlight:hover::after {
    transform: scaleX(1);
  }
}

@layer utilities {
  .line-list {
    @apply list-none p-0 m-0;
  }
  .line-list li {
    @apply relative py-1.5;
  }
  .line-list li::after {
    content: '';
    @apply absolute left-0 right-0 bottom-0 h-[1px] bg-gradient-to-r from-transparent via-gray-600/30 to-transparent;
    filter: blur(0.5px);
  }
  .line-list li:last-child::after {
    @apply hidden;
  }
}

.animate-text p, .animate-text h4, .animate-text ul, .animate-text li, .animate-text h2, .animate-text h3 {
  animation: textAnimation 0.5s ease-in-out forwards;
  opacity: 0;
}

.animate-text p:nth-child(1) { animation-delay: 0.1s; }
.animate-text p:nth-child(2) { animation-delay: 0.2s; }
.animate-text h4 { animation-delay: 0.3s; }
.animate-text ul { animation-delay: 0.4s; }
.animate-text li:nth-child(1) { animation-delay: 0.5s; }
.animate-text li:nth-child(2) { animation-delay: 0.6s; }
.animate-text li:nth-child(3) { animation-delay: 0.7s; }
.animate-text li:nth-child(4) { animation-delay: 0.8s; }
.animate-text h2:nth-child(1) { animation-delay: 0.9s; }
.animate-text p:nth-child(3) { animation-delay: 1s; }
.animate-text p:nth-child(4) { animation-delay: 1.1s; }
.animate-text h2:nth-child(2) { animation-delay: 1.2s; }
.animate-text p:nth-child(5) { animation-delay: 1.3s; }
.animate-text h3 { animation-delay: 1.4s; }
.animate-text h2:nth-child(3) { animation-delay: 1.5s; }
.animate-text p:nth-child(6) { animation-delay: 1.6s; }
.animate-text h2:nth-child(4) { animation-delay: 1.7s; }
.animate-text p:nth-child(7) { animation-delay: 1.8s; }